import React from 'react'
import "./Loader.css"
export const Loader = () => {
    return (
        <>
            <div className='loaderAlign'>
                <div class="loader">
                    <span className='loaderSpan'>English Channel</span>
                    <span className='loaderSpan'>English Channel</span>
                </div>
            </div>

        </>
    )
}